@import '../scss/ColorPartials.scss';

.Container
{
    width: 95%;
    margin:0 auto;
    padding:10px;
    display: flex;
}


.Menu {
    font-size: 0.9em;
    width: 180px;
    background: #fff;
    padding: 10px;
    padding-top: 0;
}

.Content {
    padding-left: 30px;
    flex-grow: 1;
}


.gallery-photo {
    max-height: 300px;
    width: auto;
    margin-right: 20px;
    margin-bottom: 20px;
}


.mr-1x {
    margin-right: 1rem;
}

.ml-1x {
    margin-left: 1rem;
}

.cursor-pointer {
    cursor: pointer;
}