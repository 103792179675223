@import '../../scss/colorPartials.scss';
@import '../../scss/styles.scss';

body {
    background-color: white !important;
}

.headerImgContainer {
    margin: 0 auto;
    width: 450px;
}

p.Lead {
    font-size: 1.8em;
}
p.smallLead {
    font-size:1.2em;
    color:$offwhite;
}

.form-group label {
    color: $offwhite;
}

#LoginInProgress {
    margin-left: 10px;
}

.loginDialog {
    width:600px;
    height:350px;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 30px;
    background: $grey;
    box-shadow: 10px 10px 30px $grey;
    border-radius: 10px;
}

.loginDialog input {
    background: $offwhite;
    border: none;
}


.dialogHeader {
    text-align: center;
}