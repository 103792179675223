@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:200,400');
@import 'colorPartials.scss';
@import 'modal.scss';
@import 'activityindicator.scss';

$font-stack:  Nunito, Helvetica, sans-serif;
$borderRadius: 5px;

@mixin Headings {
  margin-top: 0;
  font-weight: 400;
}

body {
  font-family: $font-stack;
  font-weight: 200;
  color: $primary-text;
  background: $offwhite;
}

h1 {
  font-size: 2em;
  @include Headings();
}

h2 {
  font-size: 1.8em;
  @include Headings();
}

h3 {
  font-size: 1.6em;
  @include Headings();
}

h4 {
  font-size: 1.4em;
  color:$grey;
  @include Headings();
}


a {
  text-decoration: none;
  color: $primary;
}


.lead {
  font-size: 1.5rem;
  margin-bottom: 5px;
}



/* Effect animations */
@keyframes saveHighlight {
  0% {
    background: #fff;
    color: #000;
  }
  30% {
    background: $green;
    color: #fff;
  }
  100% {
    background: #fff;
    color:black;
  }
}
.saveHighlight {
  animation-name: saveHighlight;
  animation-duration: 3s;
}



/* Misc bits and pieces */

.smallText {
  font-size: 0.8rem;
}

.text-center {
  text-align: center;
}

.text-green {
  color: $green !important;
}

.text-red {
  color: $red !important;
}

.text-grey {
  color: $grey;
}

.link {
  cursor: pointer;
  color: $primary;
}


.hasPointer {
  cursor: pointer;
}

.hidden {
  display: none;
}

.inline {
  display: inline-block;
  vertical-align: middle;
}

span.inline {
  line-height: 1.8rem;
  font-size: 1.2rem;
  margin-right: 0.1rem;
}

.grid-container {
  margin-bottom: 20px;
}


.sortArrow {
  cursor: pointer;
}

small {
  font-size: 0.7em;
}



/* Table styles */
@mixin tablePadding {
  padding: 5px;
}
table {
  width:100%;
  margin: 0 auto;
}

tr {
  border-bottom: 1px solid $lightgrey;
}

/*
tr:nth-of-type(even){
  background-color: $lightshade;
}
*/


tr.highlight {
  background: $fadedYellow;
}

tr.standard {
  background: $offwhite;
}

tr.smallHeaders > th {
  font-size: 0.9em;
}

th {
  text-align: left;
  font-weight: 700;
  @include tablePadding();
  border-bottom: 1px solid $lightgrey;
}

td {
  @include tablePadding();
  margin: 0px;
  font-size: 0.8rem;
  vertical-align: top;
}

td input {
  font-size: 0.8rem;
  line-height: 1rem;
}


td.width-s, th.width-s {
  width: 25px !important;
}

td.width-m, th.width-m {
  width: 50px !important;
}

td.width-l, th.width-l {
  width: 75px !important;
}

td.width-xl, th.width-xl {
  width: 100px !important;
}

td.width-2xl, th.width-2xl {
  width:150px !important;
}

tr.colourOnHover:hover {
  background: $lightgrey;
}

tr.yellowBackground {
  background: $fadedYellow;
}

tr.greenBackground {
  background-color: $fadedGreen;
}


tr.oicrow {
  height: 40px;
}



/* New Order Items */

.NewOrder-ItemRow {
  font-size: 0.8rem;
}


.size-choice-input {
  width: 60px;
}



/* Rota */

.RotaGrid {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
}

.RotaDay {
  width: 150px;
  border: 1px solid silver;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 30px;
}

.RotaDay-Date {
  font-size: 1rem;
  margin-bottom: 10px;
  padding: 5px;
}

.RotaEntry-Line {
  font-size: 0.7rem;
  padding-right:15px;
  padding-left:5px;
  padding-bottom: 15px;
  display: grid;
  grid-template-columns: 70% 30%;  
}

.RotaEntry-Selected {
  background: $fadedGreen;
}



/* Orders */
.OrderItem-Open {
  background: $fadedGreen;
}



/* Buttons */
@mixin button {
  padding:5px;
  font-size: 1em;
  border-radius: $borderRadius;
}
.btn {
  @include button();
  border: 1px solid $lightgrey;
}

.btn-fullwidth {
  width: 100%;
}

.btn-primary {
  @include button();
  background-color: $primary;
  border: 1px solid $primary;
  color: $offwhite;
}

.btn-positive {
  @include button();
  background-color: $green;
  border: 1px solid $green;
  color: $offwhite;
}

.btn-destructive {
  @include button();
  background-color: $red;
  border: 1px solid $red;
  color: $offwhite;
}

.btn-small {
  @include button();
  font-size: 0.7em;
}


.btn-tertiary {
  border: none;
}



/* Auto Complete dropdowns */
.autoComplete {
  width: 150px;
  height: 150px;
  border: 1px solid silver;
  padding: 4px;
  position: relative;
  transform: translateY(-10px);
  z-index: 100;
  background: #fff;
  overflow-x:hidden;
  overflow-y: scroll;
}


/* Alerts */
.alert {
  padding: 5px;
  line-height: 22px;
}

.alert.alert-info{
  background: $lightgrey;
}

.alert.alert-error {
  background: $red;
  color: $offwhite;
}

.alert.alert-success {
  background: $green;
  color: $offwhite;
}

.alert.alert-warning {
  background: $fadedYellow;
  color: $primary-text;
}


.dbBranding {
  display: block;
  margin-bottom: 30px;

}
.dbBranding > img {
  max-height: 100px;
}


/* Well */
.well {
  background: #fff;
  box-shadow: 0px 4px 6px $lightgrey;
  border-top: 3px solid $primary;
}

.well.well-lg {
  padding-top: 20px;
  padding-bottom: 20px;
}


/* Cards */
.card {
  background: #fff;
  margin-bottom: 20px;
  box-shadow: 0px 4px 6px $lightgrey;
  border: 1px solid $lightgrey;
  min-height: 110px;
  border-radius: $borderRadius;
}


/* Empty list */
.empty-container {
  background: $lightgrey;
  padding: 10px;
  width: 60%;
  margin: 0 auto;
}

.empty-content {
  line-height: 24px;
  text-align: center;
}

.empty-content > p {
  color: $grey;
}




/* Forms */
@mixin formcontrols {
  color: $primary-text;
  width: 95%;
  border: 1px solid $lightgrey;
  background-color: #fff;
  display: inline-block;
  border-radius: $borderRadius;
  padding: 4px;
  font-size: 0.9rem;
  font-family: Arial, Helvetica, sans-serif;
}


.form {
  border-top: 3px solid $primary;
  padding-top: 20px;
  background: $offwhite;
  box-shadow: 0px 4px 6px $lightgrey;
  background: #fff;
}


.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 10px;
}

.form-group label {
  margin-bottom: 6px;
  color: $grey;
}

.form-single-text {
  @include formcontrols();
  width: 90%;
  line-height: 1.8rem;
}

.form-multiline-text {
  @include formcontrols();
}

.form-select {
  @include formcontrols();
  height: 2.2rem;
}

.form-select-multiple {
  height: unset;
}


.form-buttons {
  background:$offwhite;
  padding: 20px 10px 20px 10px;
  text-align: right;
}


.form-text {
  @include formcontrols();
}


/* Paddings */
.mr-5{
  margin-right: 5px;
}

.mr-10{
  margin-right: 10px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}


/* AutoComplete */
.suggestion-list {
  display: block;
  position: absolute;
  background-color: $offwhite;
  min-width: 160px;
  box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
  margin-top: 0px;
  list-style-type: none;
  line-height: 1.5rem;
}



/* Dropdown menu styles */
.dropdown {
  position: relative;
  display: inline-block;
  padding-top: 5px;
  padding-bottom:5px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: $offwhite;
  min-width: 160px;
  box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}



/* iOS style switch to hide default checkbox */
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 22px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $grey;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 4px;
  background-color: #fff;
  transition: .4s;
}

input:checked + .slider {
  background-color: $green;
}

input:focus + .slider {
  box-shadow: 0 0 1px $primary;
}

input:checked + .slider:before {
  transform: translateX(10px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 18px;
}

.slider.round:before {
  border-radius: 50%;
}



/* Toast notifications */
.toast {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  color: #fff; 
  text-align: center;
  border-radius: 2px;
  padding: 16px; 
  position: fixed;
  z-index: 1;
  right: 5%; 
  top: 30px;
}

.toast.success {
  background: $green;
  color: $offwhite;
}

.toast.error {
  background: $red;
  color: $offwhite;
}

.toast.show {
  visibility: visible; 
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {top: 0; opacity: 0;} 
  to {top: 30px; opacity: 1;}
}

@keyframes fadein {
  from {top: 0; opacity: 0;}
  to {top: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {top: 30px; opacity: 1;} 
  to {top: 0; opacity: 0;}
}

@keyframes fadeout {
  from {top: 30px; opacity: 1;}
  to {top: 0; opacity: 0;}
}