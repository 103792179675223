@import '../../scss/colorPartials.scss';


.avatar {
    margin-bottom: 30px;
}

.branding > img {
    max-height: 35px;
    width: auto;
    margin-bottom: 10px;
}


.userName {
    margin-bottom: 10px;
}

.userActions {
    line-height: 1.5em;
    margin-bottom: 30px;
}

.userActions > a {
    margin-right: 20px;
}



.menuCard{
    background: $primary;
    margin-bottom: 30px;
    padding: 0;
    box-shadow: 0px 4px 6px $lightgrey;
    font-size: 0.8rem;
}

.menuCard > p {
    padding: 5px;
    font-size: 1.1em;
    margin: 0;
    background: $grey;
    color: $offwhite;
}

.menuCard > ul{
    color: $offwhite;
    list-style-type: none;
    margin-top: 5px;
    margin-left: -20px;
}

.menuCard > ul > li {
    color: $offwhite;
    line-height: 2em;
}

.menuCard > ul > li > a {
    color: $offwhite;
}