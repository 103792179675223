/* Colors */
$grey: #a9a9bc;
$lightgrey: #e4e4e4;
$lightshade: #f6f4f2;
$offwhite: #f9faf5;
$primary: #9097fd;
$red: #bf3f4a;
$green: #008a65;
$yellow: #bd9b34;
$primary-text: #464555;
$fadedYellow: rgba(189, 155, 52, 0.2);
$fadedGreen: rgba(0, 138, 101, 0.2);
