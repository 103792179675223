/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(0,0,0,0.2); /* Black w/ opacity */
  }


  .modal-visible {
    display: block;
  }
  
  /* Modal Content/Box */
  .modal-container {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 60%;
  }

  .modal-container-narrow {
    width: 30%;
  }


  /* Buttons at the footer of the dialog */
  .modal-buttons {
    text-align: right;
    border-top: 1px solid $lightgrey;
    padding-top: 15px;
  }
  
  /* The Close Button */
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }