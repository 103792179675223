.push-5, .pull-5, .push-10, .pull-10, .push-15, .pull-15, .push-20, .pull-20, .push-25, .pull-25, .push-30, .pull-30, .push-35, .pull-35, .push-40, .pull-40, .push-45, .pull-45, .push-50, .pull-50, .push-55, .pull-55, .push-60, .pull-60, .push-65, .pull-65, .push-70, .pull-70, .push-75, .pull-75, .push-80, .pull-80, .push-85, .pull-85, .push-90, .pull-90, .push-95, .pull-95, .push-33, .pull-33, .push-66, .pull-66 {
  position: relative;
}

.hide-on-desktop {
  display: none !important;
}

.grid-5 {
  float: left;
  width: 5%;
}

.prefix-5 {
  margin-left: 5%;
}

.suffix-5 {
  margin-right: 5%;
}

.push-5 {
  left: 5%;
}

.pull-5 {
  left: -5%;
}

.grid-10 {
  float: left;
  width: 10%;
}

.prefix-10 {
  margin-left: 10%;
}

.suffix-10 {
  margin-right: 10%;
}

.push-10 {
  left: 10%;
}

.pull-10 {
  left: -10%;
}

.grid-15 {
  float: left;
  width: 15%;
}

.prefix-15 {
  margin-left: 15%;
}

.suffix-15 {
  margin-right: 15%;
}

.push-15 {
  left: 15%;
}

.pull-15 {
  left: -15%;
}

.grid-20 {
  float: left;
  width: 20%;
}

.prefix-20 {
  margin-left: 20%;
}

.suffix-20 {
  margin-right: 20%;
}

.push-20 {
  left: 20%;
}

.pull-20 {
  left: -20%;
}

.grid-25 {
  float: left;
  width: 25%;
}

.prefix-25 {
  margin-left: 25%;
}

.suffix-25 {
  margin-right: 25%;
}

.push-25 {
  left: 25%;
}

.pull-25 {
  left: -25%;
}

.grid-30 {
  float: left;
  width: 30%;
}

.prefix-30 {
  margin-left: 30%;
}

.suffix-30 {
  margin-right: 30%;
}

.push-30 {
  left: 30%;
}

.pull-30 {
  left: -30%;
}

.grid-35 {
  float: left;
  width: 35%;
}

.prefix-35 {
  margin-left: 35%;
}

.suffix-35 {
  margin-right: 35%;
}

.push-35 {
  left: 35%;
}

.pull-35 {
  left: -35%;
}

.grid-40 {
  float: left;
  width: 40%;
}

.prefix-40 {
  margin-left: 40%;
}

.suffix-40 {
  margin-right: 40%;
}

.push-40 {
  left: 40%;
}

.pull-40 {
  left: -40%;
}

.grid-45 {
  float: left;
  width: 45%;
}

.prefix-45 {
  margin-left: 45%;
}

.suffix-45 {
  margin-right: 45%;
}

.push-45 {
  left: 45%;
}

.pull-45 {
  left: -45%;
}

.grid-50 {
  float: left;
  width: 50%;
}

.prefix-50 {
  margin-left: 50%;
}

.suffix-50 {
  margin-right: 50%;
}

.push-50 {
  left: 50%;
}

.pull-50 {
  left: -50%;
}

.grid-55 {
  float: left;
  width: 55%;
}

.prefix-55 {
  margin-left: 55%;
}

.suffix-55 {
  margin-right: 55%;
}

.push-55 {
  left: 55%;
}

.pull-55 {
  left: -55%;
}

.grid-60 {
  float: left;
  width: 60%;
}

.prefix-60 {
  margin-left: 60%;
}

.suffix-60 {
  margin-right: 60%;
}

.push-60 {
  left: 60%;
}

.pull-60 {
  left: -60%;
}

.grid-65 {
  float: left;
  width: 65%;
}

.prefix-65 {
  margin-left: 65%;
}

.suffix-65 {
  margin-right: 65%;
}

.push-65 {
  left: 65%;
}

.pull-65 {
  left: -65%;
}

.grid-70 {
  float: left;
  width: 70%;
}

.prefix-70 {
  margin-left: 70%;
}

.suffix-70 {
  margin-right: 70%;
}

.push-70 {
  left: 70%;
}

.pull-70 {
  left: -70%;
}

.grid-75 {
  float: left;
  width: 75%;
}

.prefix-75 {
  margin-left: 75%;
}

.suffix-75 {
  margin-right: 75%;
}

.push-75 {
  left: 75%;
}

.pull-75 {
  left: -75%;
}

.grid-80 {
  float: left;
  width: 80%;
}

.prefix-80 {
  margin-left: 80%;
}

.suffix-80 {
  margin-right: 80%;
}

.push-80 {
  left: 80%;
}

.pull-80 {
  left: -80%;
}

.grid-85 {
  float: left;
  width: 85%;
}

.prefix-85 {
  margin-left: 85%;
}

.suffix-85 {
  margin-right: 85%;
}

.push-85 {
  left: 85%;
}

.pull-85 {
  left: -85%;
}

.grid-90 {
  float: left;
  width: 90%;
}

.prefix-90 {
  margin-left: 90%;
}

.suffix-90 {
  margin-right: 90%;
}

.push-90 {
  left: 90%;
}

.pull-90 {
  left: -90%;
}

.grid-95 {
  float: left;
  width: 95%;
}

.prefix-95 {
  margin-left: 95%;
}

.suffix-95 {
  margin-right: 95%;
}

.push-95 {
  left: 95%;
}

.pull-95 {
  left: -95%;
}

.grid-33 {
  float: left;
  width: 33.33333%;
}

.prefix-33 {
  margin-left: 33.33333%;
}

.suffix-33 {
  margin-right: 33.33333%;
}

.push-33 {
  left: 33.33333%;
}

.pull-33 {
  left: -33.33333%;
}

.grid-66 {
  float: left;
  width: 66.66667%;
}

.prefix-66 {
  margin-left: 66.66667%;
}

.suffix-66 {
  margin-right: 66.66667%;
}

.push-66 {
  left: 66.66667%;
}

.pull-66 {
  left: -66.66667%;
}

.grid-100 {
  clear: both;
  width: 100%;
}
